import { parseAsString, useQueryState } from 'nuqs'

import { DEFAULT_SEARCH_TERM_LENGTH } from '../../../const'

export const useSearchTerm = () => {
    const [searchTerm, setSearchTerm] = useQueryState('searchTerm', parseAsString.withDefault(''))

    const searchTermForQuery = searchTerm && searchTerm.length >= DEFAULT_SEARCH_TERM_LENGTH ? searchTerm : null

    return {
        searchTerm,
        searchTermForQuery,
        setSearchTerm,
    }
}

import React from 'react'

import { useTranslation } from '@/lib/i18n'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { SxProps } from '@mui/material/styles'

import { CheckIcon, ExpandIcon } from './icons'

import { DataTestIds } from '../const'
import { Testable } from '../types/testable'

type SelectOption<T extends string> = {
    label: string
    value: T
}

type SelectFilterOptions<T extends string> = {
    onChange: (value: T) => void
    selectOptions: SelectOption<T>[]
    selectTitle: string
    slim?: boolean
    sx?: SxProps
    value: T
} & Testable

export const SelectFilter = <T extends string>({
    dataTestId,
    onChange,
    selectOptions,
    selectTitle,
    slim,
    sx,
    value,
}: SelectFilterOptions<T>) => {
    const { t } = useTranslation('common')

    return (
        <FormControl variant="standard" sx={{ height: slim ? 40 : undefined }}>
            <InputLabel>{selectTitle}</InputLabel>
            <Select
                data-testid={dataTestId}
                disableUnderline
                IconComponent={(props) => <ExpandIcon {...props} sx={{ width: 10 }} />}
                value={value || ''}
                onChange={(event: SelectChangeEvent) => {
                    onChange(event.target.value as T)
                }}
                renderValue={() => {
                    const selectOption = selectOptions.find((option) => option.value === value)

                    if (typeof selectOption !== 'object') {
                        return t('N/A')
                    }

                    return selectOption.label
                }}
                sx={sx}>
                {selectOptions?.map((item) => (
                    <MenuItem key={item.value} value={item.value} data-testid={DataTestIds.globals.selectValue(dataTestId, item.value)}>
                        {item.label}
                        {item.value === value && <CheckIcon color="primary" sx={{ fontSize: 20 }} />}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

import { parseAsInteger, useQueryState } from 'nuqs'

import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from '../../../const'

export const usePagination = () => {
    const [pageNumber, setPageNumber] = useQueryState('pageNumber', parseAsInteger.withDefault(DEFAULT_PAGE_NUMBER))
    const [pageSize, setPageSize] = useQueryState('pageSize', parseAsInteger.withDefault(DEFAULT_PAGE_SIZE))

    const resetPagination = () => {
        setPageSize(DEFAULT_PAGE_SIZE)
        setPageNumber(DEFAULT_PAGE_NUMBER)
    }

    const setPagination = (newPageSize: number, newPageNumber: number) => {
        setPageSize(newPageSize)
        setPageNumber(newPageNumber)
    }

    return {
        pageNumber,
        pageSize,
        resetPagination,
        setPagination,
    }
}
